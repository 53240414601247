.image-page {
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .image-page img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
    width: 50%;
  }
  
  .image-page p {
    font-size: 16px;
    max-width: 600px;
    text-align: center;
  }
  



  .App {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
  }
  
  
  .search-results {
    margin-top: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    width: 200px;
    max-height: 150px;
    overflow-y: auto;
  }
  
  .search-result-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .search-result-item:hover {
    background-color: #f0f0f0;
  }
  
  h1, h2 {
    color: #333;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    background-color: #fff;
    margin: 5px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  